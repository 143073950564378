<template>
  <v-container fluid grid-list-xl>
    <TransactionTable />
  </v-container>
</template>

<script>
import TransactionTable from "@/components/TransactionTable/TransactionTable"

export default {
  name: "Transaction",
  components: {
    TransactionTable
  }
}
</script>

<style scoped>

</style>
