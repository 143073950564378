<template>
  <v-layout row wrap class="justify-center">
    <v-flex xs12 sm12 md12 lg12>
      <div class="content">
        <div class="d-inline-flex align-center justify-start">
          <span class="fz-20 mr-3">
            Transaction History
          </span>
          <template v-if="!isLoading && Object.keys(repeatData).length > 0">
            <div v-for="(item, index) in repeatData" :key="index">
              <v-btn
                v-if="item.value"
                color="success"
                class="ma-0"
                :loading="isLoadingRepeat"
                @click="repeatPayment()"
              >
                Repeat {{ item.name }} Payment
              </v-btn>
            </div>
          </template>
        </div>

        <v-layout row wrap class="align-end">
          <v-flex xs4>
            <div class="date-wrap">
              <DatePickerRange @changed-date="changedDate" />
            </div>
          </v-flex>
          <v-flex xs4>
            <div class="label">
              Payment Method:
            </div>
            <v-select
              v-model="selectedPayment"
              :items="paymentItems"
              item-text="name"
              item-value="value"
              label="Select"
              return-object
              solo
              hide-details
              @change="refreshPagination()"
            />
          </v-flex>
          <v-flex xs4>
            <div class="label">
              Status:
            </div>
            <v-select
              v-model="selectedStatus"
              :items="statusItems"
              item-text="name"
              item-value="value"
              label="Select"
              return-object
              solo
              hide-details
              @change="refreshPagination()"
            />
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
    <v-flex xs12 sm12 md12 lg12>
      <v-card class="content">
        <v-data-table
          :items="dataList"
          :pagination.sync="pagination"
          :loading="isLoading"
          :total-items="total"
          :rows-per-page-items="[10, 25, 50, 100]"
          class="elevation-1"
          :headers-length="7"
          :headers="headers"
        >
          <template slot="items" slot-scope="props">
            <td>
              {{ props.item.type }}
            </td>
            <td>
              <span
                :class="`${props.item.amount > 0 ? 'green-text' : 'red-text'}`"
                class="bold"
              >
                ${{ props.item.amount }}
              </span>
            </td>
            <td>
              <span
                :class="`${props.item.fees > 0 ? 'green-text' : 'red-text'}`"
                class="bold"
              >
                ${{ props.item.fees }}
              </span>
            </td>
            <td>
              <span
                :class="`${+props.item.amount + +props.item.fees > 0? 'green-text': 'red-text'}`"
                class="bold"
              >
                ${{ (+props.item.amount + +props.item.fees).toFixed(2) }}
              </span>
            </td>
            <td>
              {{ dateFormat(props.item.date) }}
            </td>
            <td>
              <span :class="statusColor(props.item.status)" class="bold">
                {{ props.item.status }}</span>
            </td>
            <td>
              <v-btn
                :to="`/transaction-detailed/${props.item.id}`"
                icon
                class="ma-0 icon-hover"
              >
                <v-tooltip top color=" #383838">
                  <v-icon slot="activator">
                    settings
                  </v-icon>
                  <span>Detailed</span>
                </v-tooltip>
              </v-btn>
            </td>
          </template>
        </v-data-table>

        <div class="text-xs-center pt-2">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            @input="refreshPagination"
          />
        </div>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import DatePickerRange from "@/components/Elements/DatePickerRange"
import moment from "moment-timezone"
import SavesPagination from "@/mixins/SavesPaginationMixin"
import { fetchData } from "@/utils/response"
import {getAllTransactionRequest, repeatPaymentRequest} from "@/api/transaction"

export default {
  name: "TransactionTable",
  components: {
    DatePickerRange
  },
  mixins: [SavesPagination],
  data() {
    return {
      headers: [
        { text: "Payment Method", sortable: false, value: "type" },
        { text: "Amount", sortable: false, value: "amount" },
        { text: "Commission", sortable: false, value: "fees" },
        { text: "Total", sortable: false, value: "total" },
        { text: "Date", sortable: false, value: "date" },
        { text: "Status", sortable: false, value: "status" },
        { text: "Actions", sortable: false, value: "actions" }
      ],
      selectedPayment: { name: "All Payment Methods", value: "" },
      paymentItems: [
        { name: "All Payment Methods", value: "" },
        { name: "PayPal", value: "paypal" },
        { name: "Payoneer", value: "payoneer" }
      ],
      selectedStatus: { name: "All Statuses", value: "" },
      statusItems: [
        { name: "All Statuses", value: "" },
        { name: "Denied", value: "DENIED" },
        { name: "Pending", value: "PENDING" },
        { name: "Processing", value: "PROCESSING" },
        { name: "Success", value: "SUCCESS" },
        { name: "Canceled", value: "CANCELED" }
      ],
      repeatData: [],
      isLoadingRepeat: false,
      dataList: [],
      date_from: null,
      date_to: null,
      isLoading: false,
      pagination: {},
      total: 0,
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  methods: {
    statusColor(status) {
      if (status === "SUCCESS") {
        return "green-text"
      } else if (status === "PENDING" || status === "PROCESSING") {
        return "warn-text"
      } else {
        return "red-text"
      }
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
          this.pagination.page,
          this.pagination.rowsPerPage
      )
    },
    changedDate(val) {
      this.$nextTick(() => {
        this.date_to = val[1]
        this.date_from = val[0]
        this.refreshPagination()
      })
    },
    repeatPayment() {
      this.isLoadingRepeat = true
      repeatPaymentRequest()
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoadingRepeat = false
          })
    },
    async refresh() {
      this.isLoading = true
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        status: this.selectedStatus.value,
        type: this.selectedPayment.value,
        date_from: this.date_from,
        date_to: this.date_to
      }
      this.dataList = await getAllTransactionRequest(params)
          .then(response => {
            this.repeatData = response.data.repeat_payment
            this.total = response.data.meta.total
            return response
          })
          .then(fetchData)
          .then(data =>
              data.map(item => {
                return item
              })
          )
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoading = false
          })
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  }
}
</script>

<style scoped>
>>>.v-datatable.v-table thead tr:first-of-type {
  display: table-row;
}
.content {
  max-width: 1224px;
  margin: 0 auto;
}
.align-end{
  align-content: flex-end;
}
</style>
